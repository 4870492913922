import 'bootstrap/dist/css/bootstrap.css';
import ResultView from './ResultView';
import ResultViewFinalLaurel from './ResultViewFinalLaurel';
import ResultViewFinal from './ResultViewFinal';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
//import About from "../pages/About";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/start" element={<Home />} />
          <Route path="/result" element={<ResultView />} />
          <Route path="/resultfinal" element={<ResultViewFinal />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default App;