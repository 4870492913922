const Behaviour = (props) => {
  const data = props.data;
  const behaviourassstyle = {
    width: "30px",
    textAlign: "center",
    height: "6px",
    fontSize: "8px"
  };
  
  return (
    <>
      <div className="behavioural-assessment">
        <div className="header-for-behavioural">
          <p className="Behavioural">BEHAVIOURAL ASSESSMENT</p>
        </div>
        <table className="table2 table-bordered" id="student_behavioural_assessment_table">
          <thead>
            <tr>
              <th scope="col" style={behaviourassstyle} ></th>
              <th scope="col" style={behaviourassstyle} > A</th>
              <th scope="col" style={behaviourassstyle} > B</th>
              <th scope="col" style={behaviourassstyle} > C</th>
              <th scope="col" style={behaviourassstyle} > D</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={behaviourassstyle} >Neatness</td>
              <td style={behaviourassstyle} >{data.neatness === 1 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.neatness === 2 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.neatness === 3 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.neatness === 4 ? <span>&#9989;</span> : null}</td>
            </tr>
            <tr>
              <td style={behaviourassstyle} >Punctuality</td>
              <td style={behaviourassstyle} >{data.punctuality === 1 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.punctuality === 2 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.punctuality === 3 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.punctuality === 4 ? <span>&#9989;</span> : null}</td>
            </tr>
            <tr>
              <td style={behaviourassstyle} >Attentiveness</td>
              <td style={behaviourassstyle} >{data.attentiveness === 1 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.attentiveness === 2 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.attentiveness === 3 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.attentiveness === 4 ? <span>&#9989;</span> : null}</td>
            </tr>
            <tr>
              <td style={behaviourassstyle} >Etiquette</td>
              <td style={behaviourassstyle} >{data.etiquette === 1 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.etiquette === 2 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.etiquette === 3 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.etiquette === 4 ? <span>&#9989;</span> : null}</td>
            </tr>
            <tr>
              <td style={behaviourassstyle} >Honesty</td>
              <td style={behaviourassstyle} >{data.honesty === 1 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.honesty === 2 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.honesty === 3 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.honesty === 4 ? <span>&#9989;</span> : null}</td>
            </tr>
            <tr>
              <td style={behaviourassstyle} >Self Control</td>
              <td style={behaviourassstyle} >{data.self_control === 1 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.self_control === 2 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.self_control === 3 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.self_control === 4 ? <span>&#9989;</span> : null}</td>
            </tr>
            <tr>
              <td style={behaviourassstyle} >Obedience</td>
              <td style={behaviourassstyle} >{data.obedience === 1 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.obedience === 2 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.obedience === 3 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.obedience === 4 ? <span>&#9989;</span> : null}</td>
            </tr>
            <tr>
              <td style={behaviourassstyle} >Politeness</td>
              <td style={behaviourassstyle} >{data.politeness === 1 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.politeness === 2 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.politeness === 3 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.politeness === 4 ? <span>&#9989;</span> : null}</td>
            </tr>
            <tr>
              <td style={behaviourassstyle} >Relationship with others</td>
              <td style={behaviourassstyle} >{data.relationship_with_others === 1 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.relationship_with_others === 2 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.relationship_with_others === 3 ? <span>&#9989;</span> : null}</td>
              <td style={behaviourassstyle} >{data.relationship_with_others === 4 ? <span>&#9989;</span> : null}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Behaviour;