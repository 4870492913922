import { Button } from 'react-bootstrap';
import '../styles/printbutton.css';
const PrintButton = () => {
    const handlePrint = () => {
        window.print();
    };

    const styleI = {
        backgroundColor: 'rgb(207, 168, 168)'
    }

    return (
      <div className="print-button-container">
        <Button style={styleI} onClick={handlePrint}>
          Print
        </Button>
      </div>
    );
};

export default PrintButton;